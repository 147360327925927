import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function ContactNavLinks({ activeLink }) {
  return (
    <div className="tabs is-medium is-centered">
      <ul>
        <li className={`${activeLink === 'details' ? 'is-active' : ''}`}>
          <Link to="/contact">Details</Link>
        </li>
        <li className={`${activeLink === 'enquiry' ? 'is-active' : ''}`}>
          <Link to="/contact/enquiry">Enquiry Form</Link>
        </li>
        <li className={`${activeLink === 'nda' ? 'is-active' : ''}`}>
          <Link to="/contact/nda">NDA</Link>
        </li>
      </ul>
    </div>
  )
}
ContactNavLinks.propTypes = {
  activeLink: PropTypes.string,
}
export default ContactNavLinks
