import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Hero, Columns } from 'react-bulma-components'

function NDAComponent() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <Columns className="is-vcentered">
            <Columns.Column className="is-offset-1 is-6">
              <h1 className="title is-size-1 is-size-2-mobile">
                <span className="has-text-primary">Mutual</span> Non Disclosure
                Agreement
              </h1>
              <p>
                Even without an NDA, we always protect the interests of our
                clients. This means not sharing their industry secrets with
                competitors.
              </p>

              <p>
                If possible, we suggest working without an NDA. The reason is
                that we have worked with many clients, and talk to dozens of
                potential clients each year. It is inevitable that we hear
                similar ideas.
              </p>

              <p>
                If the NDA is important to you, please tell us enough about your
                project or business so we can evaluate if there's a conflict
                with our existing or past clients.
              </p>

              <p>
                If we determine there's no conflict, and the project is a good
                fit, and the NDA is mutual, we'll be happy to sign it.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-4 has-text-centered">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1637078545-nda.jpg?w=350"
                alt="NDA"
                placeholder="tracedSVG"
                loading="lazy"
              />
              <a
                href="https://www.datocms-assets.com/20955/1637078381-mutualnda.pdf"
                className="button is-leafy is-primary is-medium"
                target="_blank"
                rel="noreferrer"
              >
                Download NDA (PDF)
              </a>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default NDAComponent
